import LinkButton from "components/controls/links/link-button";
import MarkdownParser from "components/rich-text/markdown-parser";
import PropTypes from "prop-types";
import styles from "./minimal-header.module.scss";
import ChevronR from "assets/icons/chevron-r.svg";
import { getUrlString } from "helpers/helpers";
import LanguageFilter from "components/controls/language-filter";
const MinimalHeader = ({
  heading,
  description,
  links,
  children,
  displayLanguageFilter = false,
  availableLanguages = [],
  languageDescriptions = []
}) => {
  return <header className={styles.header} data-sentry-component="MinimalHeader" data-sentry-source-file="index.jsx">
			<div className={styles.content}>
				<div className={styles.headingContainer}>
					<h1 className={styles.heading}>
						<MarkdownParser data-sentry-element="MarkdownParser" data-sentry-source-file="index.jsx">{heading}</MarkdownParser>
					</h1>
					{displayLanguageFilter && <div className={styles.languageFilterContainer}>
						<LanguageFilter availableLanguages={availableLanguages} descriptions={languageDescriptions} />
					</div>}
				</div>
				{description && <h2 className={styles.description}><MarkdownParser>{description}</MarkdownParser></h2>}
				{children}
				{!!links?.length && <ul className={styles.linkList}>
					{links?.map(link => <li className={styles.linkItem} key={link.cta || link.text}>
							<LinkButton rightIcon={<ChevronR />} text={link.text || link.cta} href={getUrlString(link)} />
						</li>)}
				</ul>}
			</div>
		</header>;
};
MinimalHeader.propTypes = {
  children: PropTypes.any,
  description: PropTypes.string,
  // cannot be portable text (array of blocks)
  heading: PropTypes.string.isRequired,
  links: PropTypes.array,
  displayLanguageFilter: PropTypes.bool,
  availableLanguages: PropTypes.arrayOf(PropTypes.shape({
    language: PropTypes.string
  })),
  languageDescriptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    descriptions: PropTypes.string
  }))
};
export default MinimalHeader;