"use client";

import PropTypes from "prop-types";
import styles from "./hero-banner.module.scss";
import SanityImage from "@sanity-image";
import SanitySource from 'components/helper/sanity-source';
const HeroBanner = ({
  squareImage,
  wideLandscapeImage
}) => {
  if (!squareImage) {
    return null;
  }
  return <div className={styles.heroBanner} data-sentry-component="HeroBanner" data-sentry-source-file="index.jsx">
			<picture className={styles.picture} data-sentry-element="picture" data-sentry-source-file="index.jsx">
				{wideLandscapeImage && <SanitySource media="(min-aspect-ratio: 5 / 6)" image={wideLandscapeImage} />}
				{squareImage && <SanitySource media="(max-aspect-ratio: 5 / 6)" image={squareImage} />}
				<SanityImage style={{
        width: "100%"
      }} sizes={"100vw"} priority={true} image={squareImage} data-sentry-element="SanityImage" data-sentry-source-file="index.jsx" />
			</picture>
		</div>;
};
HeroBanner.propTypes = {
  squareImage: PropTypes.object,
  wideLandscapeImage: PropTypes.object
};
export default HeroBanner;